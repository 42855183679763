import React from "react";
import "./ComingSoon.css"; // We'll create this file next for styling
import logo from './OD_logo_CLEAR.png'; 
import bkvid from './BKvid.mp4';


const ComingSoon = () => {
  return (
    <div className="coming-soon-container">
      <video autoPlay muted loop className="background-video">
        <source src={bkvid} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <content>
        <img src={logo} alt="Logo" className="logo" />
        <h1>Coming Soon</h1>
        <p>ODesignz.com is under construction. Stay tuned!</p>
        <div className="social-icons">
          <a
            href="https://x.com/0designz/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-twitter"></i>
          </a>
          <a
            href="https://instagram.com/zerodesignz/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-instagram"></i>
          </a>
        </div>
      </content>
    </div>
  );
};

export default ComingSoon;
